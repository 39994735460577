<template>
<div>
  <section class="banner-une">
      <div class="container">
          <div class="banner-une-contenido">
              <img src="https://www.credix.com.mx/plantillav2/images/logo1.png" alt="Logo Credix UNE" class="img-une">
              <div class="banner-une-textos">
                  <h1>CREDIX GS, S.A. de C.V., SOFOM, E.N.R.</h1>
                  <h2>Unidad Especializada de Atención a Usuarios </h2>
                  <p>En caso de alguna consulta, reclamación o aclaración, podrá presentarla ante la UNE de atención a usuarios, quien dará respuesta en un plazo no mayor a 30 días hábiles.</p>
              </div>
          </div>
      </div>
  </section>
  <section class="une-contacto">
      <div class="container">
          <div class="titulos-iniciales">
            <div class="titulo">
                <h1>Contacto <span>UNE</span> </h1>
            </div>
          </div>
          <div class="row une-contacto-contenido">
              <div class="col-md-8">
                  <div class="row">
                      <div class="col-md-6">
                          <div class="contacto-card">
                              <h2>Domicilio</h2>
                              <p>Periférico Paseo de la República 2650, Piso 4, interior 5, Colonia Prados de Campestre, C.P. 58297, Morelia, Michoacán.</p>
                              <h2>Horario de atención</h2>
                              <p>lunes a viernes de 9:00 a.m. - 6:00 p.m.</p>
                              <h2>Correo electrónico</h2>
                              <p>unes@credix.com.mx</p>
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="contacto-card">
                              <h2>Titular de la UNE</h2>
                              <p>José Antonio Fidel Díaz Barriga Silva</p>
                              <h2>Teléfono</h2>
                              <p>443 232 1000 y 01 ext. 110 y 158</p>
                              <h2>Correo electrónico</h2>
                              <p>fidel.diaz@credix.com.mx</p>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-4">
                  <div class="card-btn-contacto">
                      <p>Si usted realizó una solicitud de aclaración y la respuesta no ha sido del todo satisfactoria, nos ponemos a sus órdenes:</p>
                      <button @click="showFm">Contáctanos</button>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section class="mb-5 regionales">
      <div class="container">
          <div class="titulos-iniciales">
            <div class="titulo">
                <h1>Encargados  <span>regionales</span> </h1>
            </div>
          </div>
          <table class="table regionales2">
                                    <thead style=" background: #00669933;">
                                    <tr>
                                        <th scope="col">Entidades
                                            Federativas</th>
                                        <th scope="col" style="text-align: center">Encargado
                                            (s) </th>
                                        <th scope="col" style="text-align: center">Domicilio (s) </th>
                                        <th scope="col" style="text-align: center">Teléfono (s) y Correo
                                            Electrónico (s)
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th scope="row">Michoacán</th>
                                        <td>José Antonio
                                            Fidel Díaz
                                            Barriga Silva</td>
                                        <td>Periférico Paseo de la República
                                            2650, Piso 4, interior 5, Colonia
                                            Prados de Campestre, C.P.
                                            58297, Morelia, Michoacán.</td>
                                        <td>800-467-2836 <br>
                                            443-232-1000 ext. 110 <br>
                                            443-232-1001 ext. 158 <br>
                                            fidel.diaz@credix.com.mx <br>
                                            unes@credix.com.mx</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Ciudad de
                                            México</th>
                                        <td>Daniel
                                            Bautista
                                            Gutiérrez
                                        </td>
                                        <td>Miguel de Cervantes Saavedra
                                            #157, Piso.- 6 Col. Ampliación
                                            Granada, delegación Miguel
                                            Hidalgo, C.P.11520, Ciudad de México.</td>
                                        <td>800-467-2836
                                            unes@credix.com.mx</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Jalisco</th>
                                        <td>Oscar
                                            Oropeza
                                            Sosa
                                        </td>
                                        <td>C. Paseo de los Virreyes 45 Piso 4 Int. 116 Col. Puerta de Hierro, C.P. 45116 Zapopan Jalisco.
                                        </td>
                                        <td>800-467-2836
                                            unes@credix.com.mx</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Nuevo León</th>
                                        <td>Alan
                                            Santillán
                                            Barragán</td>
                                        <td>Av. Insurgentes 4001 Int-A Col. Colinas de San Jerónimo C.P. 64630 Monterrey Nuevo León.
                                        </td>
                                        <td>800-467-2836
                                            unes@credix.com.mx</td>
                                    </tr>
                                    <!--tr>
                                        <th scope="row">Quintana Roo</th>
                                        <td>Cecilia
                                            Haydee
                                            Reinoso
                                            Mares
                                        </td>
                                        <td>Av. Bonampak MZ 1 LT 1 Piso 5
                                            Col. SM6 C.P. 77500 Cancún
                                            Quintana Roo.
                                        </td>
                                        <td>800-467-2836
                                            unes@credix.com.mx</td>
                                    </!--tr-->
                                    <tr>
                                        <th scope="row">San Luis Potosí</th>
                                        <td>Guillermo Andrés Burgos Fernández
                                        </td>
                                        <td>Av. Sierra Leona 360, Piso 9 Int. A ("Alttus Corporate Center & Plaza")
Col. Villantigua, C.P. 78214, San Luis Potosi, San Luis Potosí 
                                        </td>
                                        <td>800-467-2836
                                            unes@credix.com.mx</td>
                                    </tr>
                                    <!--tr>
                                        <th scope="row">Querétaro</th>
                                        <td>Andrés Mendez Payró
                                        </td>
                                        <td>Av. El Campanario #99 Local A-12 (Plaza comercial "campanario")
Col. Hacienda el campanario, C.P. 76146, Querétaro, Querétaro.
                                        </td>
                                        <td>800-467-2836
                                            unes@credix.com.mx</td>
                                    </!--tr-->
                                    </tbody>
                                </table>
      </div>
      
  </section>
  <section class="regionalesmob">
      <div class="container">
           <div class="titulos-iniciales">
            <div class="titulo">
                <h1>Encargados  <span>regionales</span> </h1>
            </div>
          </div>
        <div class="accordion mt-4">
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">Ciudad de México</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                    <div class="tgl-cnt" style="">
                        <strong> Encargado</strong>
                        <p>Daniel
                            Bautista
                            Gutiérrez
                        </p>
                        <strong>Domicilio</strong>
                        <p>Miguel de Cervantes Saavedra
                            #157, Piso.- 6 Col. Ampliación
                            Granada, delegación Miguel
                            Hidalgo, C.P.11520, Ciudad de México.</p>
                        <strong>Teléfono (s) y Correo
                            Electrónico (s)</strong>

                        <p>800-4672-836 <br>
                            unes@credix.com.mx</p>
                    </div>
                </template>
            </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">Michoacán</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                    <div class="tgl-cnt" style="">
                        <strong> Encargado</strong>
                        <p>José Antonio Fidel Díaz Barriga Silva</p>
                        <strong>Domicilio</strong>
                        <p>Periférico Paseo de la República 2650, Piso 4, interior 5, Colonia Prados de Campestre, C.P. 58297, Morelia, Michoacán.</p>
                        <strong>Teléfono (s) y Correo Electrónico (s)</strong> 
                        <p>800-4672-836 <br>
                            443-232-1000 ext. 110 <br>
                            443-232-1001 ext. 158 <br>
                            fidel.diaz@credix.com.mx <br>
                            unes@credix.com.mx</p>
                    </div>
                </template>
            </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">Jalisco</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                <template v-slot:content>
                    <div class="tgl-cnt" style="">
                        <strong> Encargado</strong>
                        <p>Oscar Oropeza Sosa</p>
                        <strong>Domicilio</strong>
                        <p>C. Paseo de los Virreyes 45 Piso 4 Int. 116 Col. Puerta de Hierro, C.P. 45116 Zapopan Jalisco.</p>
                        <strong>Teléfono (s) y Correo Electrónico (s)</strong> 
                        <p>800-467-2836 <br> unes@credix.com.mx</p>
                    </div>
                </template>
            </Pregunta>
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">Nuevo León</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                 <template v-slot:content>
                    <div class="tgl-cnt" style="">
                        <strong> Encargado</strong>
                        <p>Alan Santillán Barragán</p>
                        <strong>Domicilio</strong>
                        <p>Av. Insurgentes 4001 Int-A Col. Colinas de San Jerónimo C.P. 64630 Monterrey Nuevo León.</p>
                        <strong>Teléfono (s) y Correo Electrónico (s)</strong> 
                        <p>800-467-2836 <br> unes@credix.com.mx</p>
                    </div>
                </template>
            </Pregunta>
            <!--Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">Quintana Roo</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                 <template v-slot:content>
                    <div class="tgl-cnt" style="">
                        <strong> Encargado</strong>
                        <p>Cecilia Haydee Reinoso Mares</p>
                        <strong>Domicilio</strong>
                        <p>Av. Bonampak MZ 1 LT 1 Piso 5 Col. SM6 C.P. 77500 Cancún Quintana Roo.</p>
                        <strong>Teléfono (s) y Correo Electrónico (s)</strong> 
                        <p>800-467-2836 <br> unes@credix.com.mx</p>
                    </div>
                 </template>
            </Pregunta-->
            <Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">San Luis Potosí</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                 <template v-slot:content>
                    <div class="tgl-cnt" style="">
                        <strong> Encargado</strong>
                        <p>Guillermo Andrés Burgos Fernández</p>
                        <strong>Domicilio</strong>
                        <p>Av. Sierra Leona 360, Piso 9 Int. A ("Alttus Corporate Center & Plaza")
Col. Villantigua, C.P. 78214, San Luis Potosi, San Luis Potosí  </p>
                        <strong>Teléfono (s) y Correo Electrónico (s)</strong> 
                        <p>800-467-2836 <br> unes@credix.com.mx</p>
                    </div>
                 </template>
            </Pregunta>
            <!--Pregunta>
                <template v-slot:title>
                    <span class="accordion-title">Querétaro</span>
                    <span class="icon" aria-hidden="false"></span>
                </template>
                 <template v-slot:content>
                    <div class="tgl-cnt" style="">
                        <strong> Encargado</strong>
                        <p>Andrés Mendez Payró</p>
                        <strong>Domicilio</strong>
                        <p>Av. El Campanario #99 Local A-12 (Plaza comercial "campanario")
Col. Hacienda el campanario, C.P. 76146, Querétaro, Querétaro.</p>
                        <strong>Teléfono (s) y Correo Electrónico (s)</strong> 
                        <p>800-467-2836 <br> unes@credix.com.mx</p>
                    </div>
                 </template>
            </Pregunta-->
        </div>
      </div>
  </section>
   <section class="une-contacto">
      <div class="container">
          <div class="titulos-iniciales">
            <div class="titulo">
                <h1>Contacto <span>CONDUSEF</span> </h1>
            </div>
          </div>
          <div>
              <p class="mb-3">Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros</p>
              <p class="mb-5"> Así mismo podrá acudir a la CONDUSEF, a través de cualquier Unidad de Atención a Usuarios, en la página web <a href="https://www.condusef.gob.mx">https://www.condusef.gob.mx</a>  o por medio del CCAMER (Centro de Contacto y Atención por Medios Remotos) en el teléfono 55 53 400 999.</p>
          </div>
          <!--div class="row une-contacto-contenido">
              
                 
                      <div class="col-md-4">
                          <div class="contacto-card">
                              <h2>Domicilio</h2>
                              <p>Insurgentes Sur 762, Colonia Del Valle, Delegación Benito Juárez, C.P. 03100, México D.F.</p>
                              
                              
                          </div>
                      </div>
                      <div class="col-md-4">
                          <div class="contacto-card">
                              <h2>Centro de Atención Telefónica </h2>
                              <p class="m-0">(55) 5340 0999</p>
                              <p>800 999 8080</p>
                          </div>
                      </div>
                      <div class="col-md-4">
                          <div class="contacto-card">
                             <h2>Correo electrónico</h2>
                              <p>asesoría@condusef.gob.mx</p>
                          </div>
                      </div>
          </!--div-->
      </div>
  </section>



  <transition name="slide-fade">

        <div class="backForm" v-if="showForm">
        <div class="cerrarbtn" @click="showForm = false">
            <svg style="width:38px;height:38px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
        </div>
        <div v-if="isEnviando == 2">
            <Loading texto="Se estan enviado tus datos."/>
        </div>
        <div v-if="isEnviando == 3" class="container mt-5">
            <h4>
                {{resTxt}}
            </h4>
            <button class="btn-credix" @click="isEnviando=1">Regresar</button>
        </div>
        <div class="container" v-if="isEnviando == 1">
            <div>
                <h1>Formulario para contactar a la UNE</h1>
            </div>
            <div class="pregunta">
                    <div class="pregunta-titulo">
                        <span>1.-</span>
                        <span>¿Eres nuestro Cliente?*</span>
                    </div>
                    <div class="pregunta-input-radio">
                        <div class="inp " @click="esCliente = true;cambiarConsulta()">
                            <input type="radio" id="radioSi" name="cliente" value="si" >
                            <label for="radioSi" @click="esCliente = true">Si</label>
                        </div>
                        <div class="inp" @click="esCliente = false;cambiarConsulta()">
                            <input type="radio" id="radioNo" name="cliente" value="no" >
                            <label for="radioNo" @click="esCliente = false">No</label>
                        </div>                    
                    </div>
                    <div class="erroresf" v-if="errores.cliente">
                      <small>
                        Por favor seleccione si es cliente.
                      </small>
                    </div>
                </div>
                <div class="pregunta" v-if="esCliente">
                    <div class="pregunta-titulo">
                        <span>1-1.-</span>
                        <span>¿Cual es tu tipo de crédito?*</span>
                    </div>
                    <div class="pregunta-input-radio">
                        <div class="inp"  @click="esGarantia = true;esQuiro=false">
                            <input type="radio" id="radiogf" name="credito" v-model="mtipoCredito" value="credito1">
                            <label for="radiogf">Garantía Fiduciaria</label>
                        </div>
                        <div class="inp" @click="esGarantia = false;esQuiro=true">
                            <input type="radio" id="radioqi" name="credito" @click="esGarantia = false;esQuiro=true" v-model="mtipoCredito" value="credito2">
                            <label for="radioqi"  >Quirografario</label>
                        </div>                    
                    </div>
                    <div class="erroresf" v-if="errores.credito">
                      <small>
                        Por favor seleccione su tipo de crédito.
                      </small>
                    </div>
                </div>
                <div class="pregunta" v-if="esGarantia">
                    <div class="pregunta-titulo">
                        <span>1-2.-</span>
                        <span>Ingresa CDX*</span>
                    </div>
                    <div class="pregunta-input" :class="{'error' :errores.cdx}">
                        <input type="text" v-model="mcdx" autocomplete="off">
                    </div>
                    <div class="erroresf" v-if="errores.cdx">
                      <small>
                        Por favor ingrese su CDX.
                      </small>
                    </div>
                </div>
                <div v-if="esQuiro">
                    <img src="https://www.credix.com.mx/unes/archivos_une/ejemplo_ingreso_rfc.jpeg" alt="" style="height: 300px;">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="pregunta">
                                <div class="pregunta-titulo">
                                    <span>1-2.-</span>
                                    <span>Ingresa N° Folio / Pagaré *</span>
                                </div>
                                <div class="pregunta-input" :class="{'error' :errores.pagare}">
                                    <input type="text" v-model="mFolio" autocomplete="off">
                                </div>
                            </div>
                            <div class="erroresf" v-if="errores.pagare">
                                <small>
                                    Por favor ingrese su número de folio/pagaré.
                                </small>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="pregunta">
                                <div class="pregunta-titulo">
                                    <span>1-3.-</span>
                                    <span>RFC</span>
                                </div>
                                <div class="pregunta-input" :class="{'error' :errores.rfc}">
                                    <input type="text" v-model="mRfc" autocomplete="off">
                                </div>
                            </div>
                            <div class="erroresf" v-if="errores.rfc">
                                <small>
                                    Por favor ingrese su RFC.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pregunta">
                    <div class="pregunta-titulo">
                        <span>2.-</span>
                        <span>Régimen Fiscal*</span>
                    </div>
                    <div class="pregunta-input-radio">
                        <div class="inp" @click="rasonSocial = false">
                            <input type="radio" id="RegF1" name="RegF" value="PF" v-model="mRegimen">
                            <label for="RegF1">Persona Física</label>
                        </div>
                        <div class="inp" @click="rasonSocial = true">
                            <input type="radio" id="RegF2" name="RegF" value="PM" v-model="mRegimen">
                            <label for="RegF2">Persona Moral</label>
                        </div>    
                        <div class="inp" @click="rasonSocial = false">
                            <input type="radio" id="RegF3" name="RegF" value="PFAE" v-model="mRegimen">
                            <label for="RegF3">Persona Física con Actividad Empresarial</label>
                        </div>                 
                    </div>
                </div>
                <div class="erroresf" v-if="errores.regimen">
                    <small>
                        Por favor seleccione su régimen fiscal.
                    </small>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="pregunta">
                            <div class="pregunta-titulo">
                                <span>3.-</span>
                                <span>Asunto</span>
                            </div>
                            <div class="pregunta-input">
                                <select v-model="mAsunto"  @change="cambiarOptions()">
                                    <option value="">Seleccione:</option>
                                    <option v-for="( item,index) in llenoConsulta" :key="index" :value="item.value">{{item.text}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="pregunta">
                            <div class="pregunta-titulo">
                                <span>4.-</span>
                                <span>Consultas Frecuentes:</span>
                            </div>
                            <div class="pregunta-input">
                                <select v-model="mAsunto2">
                                    <option value="">Seleccione:</option>
                                    <option v-for="(item,index) in mAsuntoRespuesta" :key="index" :value="item.value">{{item.text}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="pregunta">
                            <div class="pregunta-titulo">
                                <span>5.-</span>
                                <span>Apellido Paterno*</span>
                            </div>
                            <div class="pregunta-input" :class="{'error' :errores.paterno}">
                                <input type="text" name="" id="" v-model="mAPaterno">
                            </div>
                        </div>
                        <div class="erroresf" v-if="errores.paterno">
                            <small>
                                Por favor ingresa tu apellido paterno.
                            </small>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="pregunta">
                            <div class="pregunta-titulo">
                                <span>6.-</span>
                                <span>Apellido Materno</span>
                            </div>
                            <div class="pregunta-input">
                                <input type="text" name="" id="" v-model="mAMaterno">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="pregunta">
                            <div class="pregunta-titulo">
                                <span>7.-</span>
                                <span>Nombre(s)*</span>
                            </div>
                            <div class="pregunta-input" :class="{'error' :errores.nombre}">
                                <input type="text" name="" id="" v-model="mNombre">
                            </div>
                        </div>
                        <div class="erroresf" v-if="errores.nombre">
                            <small>
                                Por favor ingresa tu nombre(s).
                            </small>
                        </div>
                    </div>
                </div>
                <div class="pregunta" v-if="rasonSocial">
                    <div class="pregunta-titulo">
                        <span></span>
                        <span>Razón Social*</span>
                    </div>
                    <div class="pregunta-input" :class="{'error' :errores.rs}">
                        <input type="text" v-model="mRsocial" >
                    </div>
                    <div class="erroresf" v-if="errores.rs">
                        <small>
                            Por favor ingresa tu razón social.
                        </small>
                    </div>
                </div>
                <div class="pregunta">
                    <div class="pregunta-titulo">
                        <span>8.-</span>
                        <span>Correo Electrónico</span>
                    </div>
                    <div class="pregunta-input" :class="{'error' :errores.correo}">
                        <input type="email" v-model="mCorreo">
                    </div>
                    <div class="erroresf" v-if="errores.correo">
                        <small>
                            Por favor ingresa un correo válido.
                        </small>
                    </div>
                </div>
                <div class="pregunta">
                    <div class="pregunta-titulo">
                        <span>9.-</span>
                        <span>Comentario</span>
                    </div>
                    <div class="pregunta-input">
                        <input type="text" v-model="mComentario">
                    </div>
                </div>
                <div class="enviar mt-5">
                    <button class="btn-credix" @click="enviarForm">Enviar Información</button>
                </div>
                
        </div>
    </div>
  </transition>
</div>
</template>

<script>
import Pregunta from "@/components/otros/Accordion";
import Loading from "@/components/Loading/puntos2.vue"
import axios from "axios";
export default {
    components:{
        Pregunta,
        Loading
    },
    data(){
        return {
            showForm:false,
            esCliente:false,
            esGarantia:false,
            esQuiro:false,
            mCliente:null,
            mAsunto:"",
            mAsunto2:"",
            mAsuntoRespuesta:[],
            llenoConsulta:[],
            mNombre:"",
            mAPaterno:"",
            mAMaterno:"",
            mCorreo:"",
            mComentario:"",
            mRegimen:null,
            mtipoCredito:"",
            mcdx:"",
            mFolio:"",
            mRfc:"",
            mRsocial:"",
            rasonSocial:false,
            errores:{
                cliente:false,
                regimen:false,
                paterno:false,
                nombre:false,
                correo:false,
                credito:false,
                cdx:false,
                pagare:false,
                rs:false,
                rfc:false
            },
            isEnviando:1,
            resTxt:""
        }
    },
    methods:{ 
        enviarForm(){
            if(this.revisarForm()){
                this.isEnviando = 2;
                let formUne = new FormData()
                formUne.append("radioCliente",this.mCliente);
                formUne.append("radioCredito",this.mCliente);
                formUne.append("folio_pagare",this.mCliente);
                formUne.append("rfc",this.mCliente);
                formUne.append("radioRegimen",this.mCliente);
                formUne.append("asunto",this.mCliente);
                formUne.append("consultas_frecuentes",this.mCliente);
                formUne.append("ap_paterno",this.mCliente);
                formUne.append("ap_materno",this.mCliente);
                formUne.append("nombre_completo",this.mCliente);
                formUne.append("correo_electronico",this.mCliente);
                formUne.append("comentario",this.mCliente);
                axios.post('/generar_solicitud_unes.php',formUne)
                .then((res)=>{
                    console.log(res)
                    if(res.data.status == 1){
                        this.resTxt = res.data.message
                        setTimeout(() => {
                            this.isEnviando = 3

                        }, 2000);
                    }else{
                        this.resTxt = res.data.message
                        this.isEnviando = 3
                    }
                })
                .catch((err)=>{
                    console.log(err)
                    this.isEnviando = 1
                });
            }
        },
        
        revisarForm(){
            this.errores.cliente = (this.mCliente == null)?true:false
            if(this.mCliente == "si"){
                this.errores.credito = (this.mtipoCredito == "")?true:false
                if(this.mtipoCredito == "credito1"){
                    
                    this.errores.cdx = (this.mcdx == "")?true:false
                }else{
                    this.errores.pagare = (this.mFolio == "")?true:false
                    this.errores.rfc = (this.mRfc == "")?true:false
                }
                
               
            }
            this.errores.regimen = (this.mRegimen == null)?true:false
            this.errores.paterno = (this.mAPaterno == "")?true:false
            this.errores.nombre = (this.mNombre == "")?true:false
            this.errores.correo = (!this.validarEmail(this.mCorreo))?true:false
            
            return (!this.errores.cliente && !this.errores.regimen &&!this.errores.paterno &&!this.errores.nombre &&!this.errores.correo &&!this.errores.credito &&!this.errores.cdx && !this.errores.pagare && !this.errores.rfc)
        },
        validarEmail(email){
            return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        },
        showFm(){
            this.showForm = true
        },
        toggleAccordion(event){
          if( event.path[1].ariaExpanded == "false"){
               event.path[1].ariaExpanded = "true"
          } else{
               event.path[1].ariaExpanded = "false"
          }
        },
        cambiarConsulta(){
           
            let lleno1 = [
                {
                    value:"consulta",
                    text:"Consulta"
                },
                {
                    value:"aclaracion",
                    text:"Aclaración"
                },
                {
                    value:"reclamo",
                    text:"Reclamación"
                }
            ];
            let lleno2 = [
                {
                    value:"consulta",
                    text:"Consulta"
                }
            ]
            
            if(this.esCliente){
                this.llenoConsulta = lleno1
                this.mCliente = "si"
            }else{
                this.llenoConsulta = lleno2
                this.mCliente = "no"
            }
            
        },
        cambiarOptions(){
          
            let consulta = [
            {value:'Solicitud de estados de cuenta {cobranza} <1207>',text:'Solicitud de estados de cuenta'},
            {value:'Orientación sobre funcionamiento de productos y servicios financieros {comercial} <1079>',text:'Orientación sobre funcionamiento de productos y servicios financieros'},
            {value:'Negociación de créditos (Orientación) {cobranza} <666>',text:'Negociación de créditos (Orientación)'},
            {value:'Actualización del Historial Crediticio {cartera} <1200>',text:'Actualización del Historial Crediticio'},
            {value:'Información para la cancelación de producto o servicio {cobranza} <1217>',text:'Información para la cancelación de producto o servicio'},
            {value:'Consulta del monto de las primas de los seguros por los que respecta la amortización {cobranza} <1204>',text:'Consulta del monto de las primas de los seguros por los que respecta la amortización'},
            {value:'Información para la entrega de la carta liberación {cobranza} <1221>',text:'Información para la entrega de la carta liberación'},];
            
            let aclaracion = [
            {value:'Inconformidad con el saldo del crédito o del monto de las amortizaciones {cobranza} <0577>',text:'Inconformidad con el saldo del crédito o del monto de las amortizaciones'},
            {value:'Inconformidad con el monto de los gastos de apertura de crédito {comercial} <0582>',text:'Inconformidad con el monto de los gastos de apertura de crédito'},
            {value:'Cobro de Comisión por pago efectuado no aplicado {cobranza} <0840>',text:'Cobro de Comisión por pago efectuado no aplicado'},
            {value:'Cobro no reconocido de Intereses Ordinarios o Moratorios {cobranza} <0841>',text:'Cobro no reconocido de Intereses Ordinarios o Moratorios'},
            {value:'Inconformidad con el cobro de comisión no reconocida o no pactada en el contrato {cobranza} <0849>',text:'Inconformidad con el cobro de comisión no reconocida o no pactada en el contrato'},
            {value:'Inconformidad con el cobro de intereses moratorios y accesorios durante el proceso de aclaración {cobranza} <0853>',text:'Inconformidad con el cobro de intereses moratorios y accesorios durante el proceso de aclaración.'},
            {value:'Inconformidad con el cobro de intereses no pactados en el contrato {cobranza} <0854>',text:'Inconformidad con el cobro de intereses no pactados en el contrato'},
            {value:'Inconformidad con el cobro de productos o servicios no contratados por el usuario, cliente o socio {cobranza} <0967>',text:'Inconformidad con el cobro de productos o servicios no contratados por el usuario, cliente o socio'},
            {value:'Pago o deposito no aplicado total o parcialmente al producto o servicio {cobranza} <1081>',text:'Pago o deposito no aplicado total o parcialmente al producto o servicio'},
            ];
            
            let reclamo = [
            {value:'Solicitud de estados de cuenta no atendida {cobranza} <0188>',text:'Solicitud de estados de cuenta no atendida'},
            {value:'Inconformidad con la recepción de pagos {cobranza} <0353>',text:'Inconformidad con la recepción de pagos'},
            {value:'Inconformidad con el saldo del crédito del monto de amortizaciones {cobranza} <0577>',text:'Inconformidad con el saldo del crédito del monto de amortizaciones'},
            {value:'Inconformidad con el monto de los gastos de apertura del crédito {comercial} <0582>',text:'Inconformidad con el monto de los gastos de apertura del crédito'},
            {value:'Crédito aprobado sin la entrega de recursos {comercial} <0583>',text:'Crédito aprobado sin la entrega de recursos'},
            {value:'Consulta de saldos y movimientos no atendida {cobranza} <0712>',text:'Consulta de saldos y movimientos no atendida'},
            {value:'Actualización de historial crédito no realizada {cartera} <0730>',text:'Actualización de historial crédito no realizada'},
            {value:'Crédito no reconocido en el historial crediticio {cartera} <0835>',text:'Crédito no reconocido en el historial crediticio'},
            {value:'Cobro de comisión por pago efectuado no aplicado {cobranza} <0840>',text:'Cobro de comisión por pago efectuado no aplicado'},
            {value:'Cobro no reconocido de intereses ordinarios o moratorios {cobranza} <0841>',text:'Cobro no reconocido de intereses ordinarios o moratorios'},
            {value:'Convenio/ reestructura de pago no respetado por la institución {cobranza} <0842>',text:'Convenio/ reestructura de pago no respetado por la institución'},
            {value:'Eliminación de historial crediticio no realizada {cartera} <0844>',text:'Eliminación de historial crediticio no realizada'},
            {value:'Inconformidad con el cobro de comisión no reconocida o no pactada en el contrato {cobranza} <0849>',text:'Inconformidad con el cobro de comisión no reconocida o no pactada en el contrato'},
            {value:'Inconformidad con el cobro de intereses moratorios y accesorios durante el proceso de aclaración {cobranza} <0853>',text:'Inconformidad con el cobro de intereses moratorios y accesorios durante el proceso de aclaración'},
            {value:'Inconformidad con el cobro de intereses no pactados en el contrato {cobranza} <0854>',text:'Inconformidad con el cobro de intereses no pactados en el contrato'},
            {value:'La institución no entrega la carta liberación de adeudos, carta finiquito, facturas. Garantías, gravamen o primer testimonio {cobranza} <0858>',text:'La institución no entrega la carta liberación de adeudos, carta finiquito, facturas. Garantías, gravamen o primer testimonio'},
            {value:'Incumplimiento con los términos del contrato {cobranza} <1042>',text:'Incumplimiento con los términos del contrato'},
            {value:'Pago o deposito no aplicado total o parcialmente al producto o servicio {cobranza} <1081>',text:'Pago o deposito no aplicado total o parcialmente al producto o servicio'},
            {value:'Solicitud de cancelación de producto o servicio no atendida o no aplicada {cobranza} <1101>',text:'Solicitud de cancelación de producto o servicio no atendida o no aplicada'},
            {value:'La institución financiera no reflejo en tiempo el pago efectuado por el usuario o cliente para la actualización del historial crediticio {cartera} <1323>',text:'La institución financiera no reflejo en tiempo el pago efectuado por el usuario o cliente para la actualización del historial crediticio'},
            ];
            let consulta2 = [{
                value:"Orientación sobre funcionamiento de productos y servicios financieros {comercial} <1079>",
                text:"Orientación sobre funcionamiento de productos y servicios financieros"
            }]
            if(this.esCliente == true){
                switch(this.mAsunto){
                    case 'consulta': this.mAsuntoRespuesta = consulta; break;
                    case 'aclaracion':this.mAsuntoRespuesta = aclaracion; break;
                    case 'reclamo':this.mAsuntoRespuesta = reclamo; break;
                }
            }
            if(this.mCliente=="no"){
                this.mAsuntoRespuesta = consulta2
            }
        }
    }
}
</script>

<style lang="css" scoped>
.erroresf small{
  color: #d00524;
  font-weight: 600;
}
.banner-une-contenido {
  display: flex;
}
.banner-une {
  padding: 4rem 0;
}
.banner-une-contenido {
  justify-content: space-between;
}
.banner-une-textos {
  text-align: right;
}
.banner-une-textos h1 {
  font-size: 20px;
  color: #132c35;
  font-weight: 400;
}
.banner-une-textos h2 {
  color: #006699;
  padding: 0.2rem 0 2rem 0;
}
.banner-une-textos p {
  width: 80%;
  float: right;
}
.une-contacto-contenido {
  padding: 2rem 0;
}
.une-contacto-contenido .contacto-card h2 {
  font-size: 15px;
  font-weight: 400;
  color: #006699;
}
.une-contacto-contenido .card-btn-contacto {
  text-align: center;
}
.une-contacto-contenido .card-btn-contacto p {
  font-size: 12px;
}
.une-contacto-contenido .card-btn-contacto button {
  position: relative;
  font-family: inherit;
  line-height: inherit;
  font-weight: 600;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity, box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 0px;
  box-shadow: #0066995d 0px 3px 12px 0px;
  padding: 10px 2rem;
  min-height: 40px;
  background-color: #006699;
  color: #fff;
  margin-top: 2rem;
  border-radius: 4px;
}
.img-une {
  width: 200px;
}
.cerrarbtn {
  position: fixed;
  top: 2rem;
  right: 4rem;
}
.cerrarbtn svg {
  cursor: pointer;
  color: #006699;
}
.backForm {
  opacity: 1;
  background: #fff;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  margin: auto;
  left: 0;
  padding: 2rem 0;
  overflow: auto;
}
.backForm h1 {
  color: #006699;
}
.pregunta {
  margin-top: 1.4rem;
}
.pregunta-titulo {
  margin: 0px;
  max-width: 100%;
  font-weight: unset;
  font-size: 14px;
  line-height: 32px;
}
.pregunta-input-radio {
  display: flex;
  cursor: pointer;
}
.pregunta-input-radio .inp {
  position: relative;
  margin-right: 2rem;
  cursor: pointer;
}
.pregunta-input.error{
background-color: #d0022224;
}
.pregunta-input-radio .inp input {
  appearance: none;
  height: 40px;
  width: 145px;
  min-width: 115px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  user-select: none;
  margin-right: 6px;
  border-radius: 4px;
  background-color: rgba(26, 145, 162, 0.1);
  color: rgba(26, 145, 162, 0.6);
  box-shadow: rgba(0, 102, 153, 0.6) 0px 0px 0px 1px inset;
  transition: all 0.1s ease-out 0s;
  max-width: 400px;
}
.pregunta-input-radio .inp input + label {
  text-align: center;
  cursor: pointer;
}
.pregunta-input-radio .inp input:checked {
  background-color: rgba(0, 102, 153, 0.8);
}
.pregunta-input-radio .inp input:checked + label {
  color: white;
}
.pregunta-input-radio .inp label {
  position: absolute;
  top: 45%;
  width: 100%;
  font-size: 11px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #069;
}
.pregunta-input.file input {
  display: none;
}
.pregunta-input.file span {
  margin-left: 1rem;
}
.pregunta-input.file label {
  position: relative;
  font-family: inherit;
  line-height: inherit;
  font-weight: 700;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity, box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
  padding: 6px 14px;
  min-height: 40px;
  background-color: white;
  color: #006699;
  margin-top: 2rem;
  border-radius: 4px;
}
.pregunta-input input, .pregunta-input select {
  display: block;
  width: 100%;
  font-family: inherit;
  color: #1a91a2;
  padding: 0px 0px 8px;
  border: none;
  outline: none;
  border-radius: 0px;
  appearance: none;
  background-image: none;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  transform: translateZ(0px);
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  line-height: unset;
  -webkit-text-fill-color: #006699;
  animation: 1ms ease 0s 1 normal none running native-autofill-in;
  transition: background-color 9e ease 0s, box-shadow 0.1s ease-out 0s;
  box-shadow: rgba(26, 145, 162, 0.3) 0px 1px;
  background-color: transparent !important;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to {
  /* .slide-fade-leave-active below version 2.1.8 */
  transform: translateX(10px);
  opacity: 0;
}
.regionalesmob {
  display: none;
}
.regionales2 thead th {
  color: #006699;
  border-bottom: 0.5px solid #006699;
}
@media only screen and (max-width: 768px) {
  .pregunta-input-radio {
    flex-wrap: wrap;
  }
  .backForm {
    width: 100%;
  }
  .une-contacto {
    margin-top: 2rem;
  }
  .regionales {
    display: none;
  }
  .banner-une {
    margin-bottom: 4rem;
    padding: 1rem 0;
  }
  .banner-une-contenido {
    display: block;
    margin-bottom: 2rem;
  }
  .banner-une-contenido .banner-une-textos {
    margin-top: 2rem;
    text-align: left;
  }
  .banner-une-contenido .banner-une-textos h2 {
    padding-bottom: 0.5rem;
  }
  .banner-une-contenido .banner-une-textos p {
    width: 100%;
  }
  .regionalesmob {
    display: block;
  }
  .accordion .accordion-content p {
    margin: 0 0 0.5rem 0 !important;
  }
  .img-une {
    display: none;
  }
}

</style>