<template>
<div>
 <section class="header3">
    <div class="imgLogo">
     <a href="/"> <img src="https://credix.com.mx/plantillav2/images/logo-2.png" alt="Logo Credix" width="150"></a>
    </div> 
  </section>
  <section class="form-prov">
      <div class="sec-left">
        <h1></h1>
        <div class="beneficios-login py-4">
          <div class="itemben">
            <div class="icon">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
              </svg>
            </div>
            <h5>Recuerda que nosotros no pedimos pagos por adelantado.</h5>
          </div>
          <div class="itemben">
            <div class="icon">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
              </svg>
            </div>
            <h5>Trabajamos para brindarte un servicio de calidad.</h5>
          </div>
          <div class="itemben">
            <div class="icon">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
              </svg>
            </div>
            <h5>Si tienes dudas no dudes en contactarnos en nuestra sección de atención a clientes.</h5>
          </div>
        </div>
      </div>
      <div class="sec-rig">
        <div class="container-form">
          <div class="formulario">
              <div class="pasos">
                <div class="paso paso1"  :class="[(step > 1 && step !=1 ?  'despues':''),(step == 1 ? 'activo':'antes')]">
                  <h3 class="mb-5">
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z" />
                    </svg>
                     <span class="ms-3 ">Datos del Cliente</span> 
                  </h3>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Nombre del Acreditado</p>
                        <div class="input" :class="{'error' :errores.acreditado}">
                          <input type="text" v-model="mnombreareditado" >
                        </div>
                        <div class="erroresf" v-if="errores.acreditado">
                          <small>Escribe un nombre válido.</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Número de Contrato</p>
                        <div class="input" :class="{'error' :errores.contrato}">
                          <input type="text" placeholder="Ej. CDX/0001"  v-model="mcontrato" v-mask="`cdx/`+'#######'">
                        </div>
                        <div class="erroresf" v-if="errores.contrato">
                          <small>Escribe un número de contrato válido.</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Correo Electrónico</p>
                        <div class="input" :class="{'error' :errores.acreditado}">
                          <input type="text" v-model="mcorreo" >
                        </div>
                        <div class="erroresf" v-if="errores.acreditado">
                          <small>Escribe un correo válido.</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="paso paso2"  :class="[(step > 2 && step !=2 ?  'despues':''),(step == 2 ? 'activo':'antes')]">
                  <h3 class="mb-5">
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M22,3H2A2,2 0 0,0 0,5V19A2,2 0 0,0 2,21H22A2,2 0 0,0 24,19V5A2,2 0 0,0 22,3M22,19H2V5H22V19M19,18L21,16L19.5,14H17.85C17.63,13.37 17.5,12.7 17.5,12C17.5,11.3 17.63,10.63 17.85,10H19.5L21,8L19,6C17.7,7 16.73,8.38 16.28,10C16.1,10.64 16,11.31 16,12C16,12.69 16.1,13.36 16.28,14C16.73,15.61 17.7,17 19,18M9,12A3,3 0 0,0 12,9A3,3 0 0,0 9,6A3,3 0 0,0 6,9A3,3 0 0,0 9,12M9,8A1,1 0 0,1 10,9A1,1 0 0,1 9,10A1,1 0 0,1 8,9A1,1 0 0,1 9,8M15,16.59C15,14.09 11.03,13 9,13C6.97,13 3,14.09 3,16.59V18H15V16.59M5.5,16C6.22,15.5 7.7,15 9,15C10.3,15 11.77,15.5 12.5,16H5.5Z" />
                    </svg>
                     <span class="ml-3">¿Quién registra?</span> 
                  </h3>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Nombre(s)</p>
                        <div class="input" :class="{'error' :errores.nombre}">
                          <input type="text"  v-model="mnombre">
                        </div>
                        <div class="erroresf" v-if="errores.nombre">
                          <small>Escribe un nombre válido.</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Apellido Paterno</p>
                        <div class="input" :class="{'error' :errores.apellidop}">
                          <input type="text"  v-model="mapellidop" >
                        </div>
                        <div class="erroresf" v-if="errores.apellidop">
                          <small>Escribe un apellido paterno válido.</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Apellido Materno</p>
                        <div class="input" :class="{'error' :errores.apellidom}">
                          <input type="text"  v-model="mapellidom" >
                        </div>
                        <div class="erroresf" v-if="errores.apellidom">
                          <small>Escribe un apellido materno válido.</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="aceptot">Al dar clic en REGISTRARME aceptas nuestro <a href="/aviso-de-privacidad">aviso de privacidad</a>, y utilizar medios electrónicos de identificación, tales como el código de verificación.</p>
                </div>
                <div class="paso paso3"  :class="[(step > 3 && step !=3 ?  'despues':''),(step == 3 ? 'activo':'antes')]">
                  <div class="spinner">
                    <Loading/>
                  </div>
                </div>
                <div class="paso paso4" v-if="step==4">
                  <div class="final-registro">
                    <h2>Tu cuenta ha sido creada con éxito</h2>
                    <p>Ahora solo espera a que se active, <br>descuida nosotros nos encargamos de eso.</p>
                    <router-link to="/"> <button class="btn-credix">Regresar al inicio</button></router-link>
                  </div>
                </div>
                <div class="paso paso4" v-if="step==5">
                  <div class="final-registro">
                    <h2>Ocurrio un error.</h2>
                    <p>{{errorAxios}}</p>
                    <button class="btn-credix" @click="step=1">Regresar al inicio</button>
                  </div>
                </div>



                <div class="controlForm">
                  <button class="btn-credix-outline" @click="step--" :disabled="step==1" v-if="step<3">Atrás</button>
                  <button class="btn-credix-outline" @click="siguiente(step)" v-if="step<2">Siguiente</button>
                  <button class="btn-credix-outline" @click="enviarDatos" v-if="step==2" >Registrarme</button>
                </div>
                <div class="accountnow">
                  <h1>¿Ya tienes una cuenta? <router-link to="/iniciar-sesion">Inicia sesión ahora</router-link></h1>
                </div>
              </div>
          </div>
          <div class="footerform">
              <div class="step " :class="{'active':step>=1}">
                <div class="icon">
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M22,3H2C0.91,3.04 0.04,3.91 0,5V19C0.04,20.09 0.91,20.96 2,21H22C23.09,20.96 23.96,20.09 24,19V5C23.96,3.91 23.09,3.04 22,3M22,19H2V5H22V19M14,17V15.75C14,14.09 10.66,13.25 9,13.25C7.34,13.25 4,14.09 4,15.75V17H14M9,7A2.5,2.5 0 0,0 6.5,9.5A2.5,2.5 0 0,0 9,12A2.5,2.5 0 0,0 11.5,9.5A2.5,2.5 0 0,0 9,7M14,7V8H20V7H14M14,9V10H20V9H14M14,11V12H18V11H14" />
                  </svg>
                </div>
              </div>
              <div class="step" :class="{'active':step>=2}">
                <div class="icon">
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24" >
                      <path fill="currentColor" d="M11 9C11 10.66 9.66 12 8 12C6.34 12 5 10.66 5 9C5 7.34 6.34 6 8 6C9.66 6 11 7.34 11 9M14 20H2V18C2 15.79 4.69 14 8 14C11.31 14 14 15.79 14 18M22 12V14H13V12M22 8V10H13V8M22 4V6H13V4Z" />
                  </svg>
                </div>
              </div>
              
          </div>
        </div>
      </div>
  </section>
</div>
 
</template>

<script>
import Loading from "@/components/Loading/provedor.vue"
import axios from "axios"
export default {
  components: {
      Loading
    },
  data:()=>({
    step:1,
    verPass:false,
    verPass2:false,
    mnombre:"",
    mapellidop:"",
    mapellidom:"",
    mcorreo:"",
    mcontrato:"",
    mnombreareditado:"",
    errorAxios:null,
    errores:{
      acreditado:false,
      contrato:false,
      correo:false,
      nombre:false,
      apellidop:false,
      apellidom:false
    }
  }),
  computed:{
    stepperProgress(){
      return (100/3) * (this.step - 1) + "%"
    }
  },
  methods:{
    siguiente(form){
      if(form==1){
        this.errores.acreditado = (this.mnombreareditado=="")?true:false
        this.errores.contrato = (this.mcontrato=="")?true:false
        this.errores.correo = (!this.validarEmail(this.mcorreo))?true:false
        if(!this.errores.acreditado && !this.errores.contrato && !this.errores.correo ){
            this.step++
        }
      }
    },
    validarEmail(email){
      return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    },
    enviarDatos(){
      this.errores.nombre = (this.mnombre == "")?true:false
      this.errores.apellidop = (this.mapellidop == "")?true:false
      this.errores.apellidom = (this.mapellidom == "")?true:false
      if(!this.errores.nombre && !this.errores.apellidop && !this.errores.apellidom){
        console.log("Enviando")
        this.step=3
        var formCliente = new FormData()
        formCliente.append("strAcreditado",this.mnombreareditado)
        formCliente.append("strCdx",this.mcontrato)
        formCliente.append("strEmail",this.mcorreo)
        formCliente.append("strNombre",this.mnombre)
        formCliente.append("strPaterno",this.mapellidop)
        formCliente.append("strMaterno",this.mapellidom)
        formCliente.append("btnRegistrarse","Registrarse")
        axios.post('https://credix.com.mx/registrarCliente2.php',formCliente)
        .then((res)=>{
          console.log(res)
          if(res.data.error == 0){
            
            setTimeout(() => {
              this.step=4
            }, 5000);
          }else{
            this.step=5
            this.errorAxios = res.data.mensaje
          }
        })
        .catch((err)=>{
          this.step=5
         console.log(err)
        })
      }
    },
    VerContra(el){
      if(el == 1){
        this.verPass = !this.verPass
      }
      if(el == 2){
        this.verPass2 = !this.verPass2
      }
    }
  }
}
</script>

<style lang="css" scoped>
  .accountnow{
    margin-top: 20px;
  }
  .accountnow h1{
      font-size: 15px; 
  }
  .accountnow h1 a{
    color: #006699;
  }
.erroresf small{
  color: #d00524;
  font-weight: 600;
}


.errorespass{
  color:#d00524;
}
.grupo-input .input.error{
  background-color: #d0022224;
}
.aceptot {
  font-size: 9pt;
  text-align: justify;
  color: #000;
  opacity: 0.4;
}
.aceptot a {
  color: #006699;
  text-decoration: none;
}
.header3 {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  padding: 0.5rem 1rem;
}
.header3 .imgLogo{
  margin-left: 2rem;
}
.final-registro {
  color: #006699;
}
.container-form {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.container-form .formulario {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 4rem;
}
.container-form .formulario {
  height: 90%;
}
.container-form .footerform {
  display: flex;
  justify-content: center;
  height: 10%;
  box-shadow: 0px -1px 8px 1px #00669966;
}
.container-form .footerform .step {
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-form .footerform .step .icon {
  border: 1px solid #006699;
  padding: 9px 9px;
  border-radius: 50px;
  color: #006699;
}
.container-form .footerform .step.active .icon {
  color: #fff;
  background-color: #006699;
}
.container-form .icon {
  color: #006699;
}
.container-form .input {
  border-bottom: 1px solid #006699;
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
}
.container-form input {
  width: 100%;
  border: none;
  background: none;
  letter-spacing: 2px;
}
.container-form input:focus-visible {
  border: none;
  outline: none;
}
.form-prov {
  width: 100%;
  background: #006699;
  height: 100vh;
  display: flex;
}
.form-prov .sec-left {
  width: 30%;
  height: 100%;
  color: #fff;
  background-color: #006699;
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  justify-content: center;
  align-items: center;
}
.form-prov .sec-left .itemben {
  display: flex;
  margin: 1rem 0;
}
.form-prov .sec-left .itemben h5 {
  margin-left: 20px;
  font-weight: lighter;
}
.form-prov .sec-rig {
  width: 70%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-prov select {
  width: 100%;
  border: none;
  outline: none;
}
.form-prov small {
  font-size: 9px;
  font-style: italic;
  margin: 0;
}
.form-prov input[type=file] {
  width: 100%;
  font-size: 10px;
  letter-spacing: 0;
}
.grupo-input {
  margin-bottom: 1rem;
}
.grupo-input a {
  text-decoration: none;
  color: #006699;
}
.grupo-input p {
  margin-bottom: 0px;
}
.grupo-input .forget {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.pasos {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.pasos .paso {
  overflow: hidden;
}
.pasos .paso h3 {
  font-weight: 300;
  color: #006699;
}
.pasos .paso.activo {
  position: relative;
  width: 100%;
  height: 70%;
  padding-top: 0px;
  transition: transform 600ms ease 0s, opacity 300ms ease 0s;
}
.pasos .paso.antes {
  position: absolute;
  width: 100%;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
  height: 0%;
  padding-top: 0px;
  pointer-events: none;
  transition: transform 600ms ease 0s, opacity 300ms ease 0s;
  opacity: 0;
  transform: translateY(100vh);
}
.pasos .paso.despues {
  position: absolute;
  width: 100%;
  height: 70%;
  padding-top: 0px;
  pointer-events: none;
  transition: transform 600ms ease 0s, opacity 300ms ease 0s;
  opacity: 0;
  transform: translateY(-100vh);
}
.controlForm {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.controlForm button {
  margin-left: 15px;
}
.eyePass {
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .header3 {
    width: 100%;
    padding: 1rem 1rem;
    z-index: 999;
    background: white;
  }
  .form-prov {
    padding-top: 3rem;
    height: 100%;
  }
  .form-prov .sec-left {
    display: none;
  }
  .form-prov .sec-rig {
    width: 100%;
  }
  .form-prov .sec-rig .container-form {
    display: block;
  }
  .form-prov .sec-rig .container-form .formulario {
    padding:3rem 1.5rem;
    height: 100%;
    display: block;
  }
  .form-prov .sec-rig .container-form .formulario .pasos {
    display: block;
    margin-bottom: 20rem;
    height: auto;
  }
  .form-prov .sec-rig .container-form .formulario .pasos .paso.activo {
    height: 100%;
  }
  .form-prov .sec-rig .container-form .footerform {
    padding: 10px 0;
    z-index: 999;
    position: fixed;
    bottom: 0px;
    display: block;
    left: 0;
    background-color: #fff;
    width: 100%;
  }
  .form-prov .sec-rig .container-form .footerform .step {
    float: left;
    text-align: center;
    margin: 0;
    width: 25%;
  }
  .header3 .imgLogo{
  margin-left: 0rem;
}
}

</style>